<template>
  <Form :validation-schema="schema" ref="productThumbnailRef" v-slot="{ errors }">
    <div class="page-header page-header-height">
      <div class="row align-items-center">
        <div class="col-lg mb-3 mb-lg-0">
          <div class="d-flex align-items-center">
            <svg
              class="page-header-img"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.66675 12V9.33329C2.66675 5.33329 5.33341 2.66663 9.33341 2.66663H22.6667C26.6667 2.66663 29.3334 5.33329 29.3334 9.33329V12"
                stroke="#1F2629"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.66675 20V22.6667C2.66675 26.6667 5.33341 29.3333 9.33341 29.3333H22.6667C26.6667 29.3333 29.3334 26.6667 29.3334 22.6667V20"
                stroke="#1F2629"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.93335 12.3467L16 16.44L23.0133 12.3734"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 23.6933V16.4266"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.3467 8.3867L10.0801 10.7601C9.12006 11.2934 8.32007 12.6401 8.32007 13.7467V18.2668C8.32007 19.3734 9.10672 20.7201 10.0801 21.2534L14.3467 23.6267C15.2534 24.1334 16.7467 24.1334 17.6667 23.6267L21.9334 21.2534C22.8934 20.7201 23.6934 19.3734 23.6934 18.2668V13.7467C23.6934 12.6401 22.9067 11.2934 21.9334 10.7601L17.6667 8.3867C16.7467 7.8667 15.2534 7.8667 14.3467 8.3867Z"
                stroke="#3AAB14"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div class="heading-04 fw-semibold d-none d-sm-block">Products</div>
            <div class="heading-02 fw-semibold d-sm-none">Products</div>
          </div>
          <div class="label-01 fw-semibold mt-2 d-flex align-items-center">
            <router-link class="text-neutral-05" target="_blank" :to="{ name: 'Products' }">
              Products
            </router-link>
            <img
              src="../../assets/images/icons/arrow-right-12x12.svg"
              class="ms-2 ps-1 me-2 pe-1"
            />
            <span class="text-success-05"> Edit product</span>
          </div>
        </div>
        <div class="col-sm-auto pe-0 mb-3 mb-sm-0">
          <button
            type="button"
            class="button btn-small btn-neutral-solid"
            style="width: 125px"
            @click="Submit(true)"
          >
            Save as draft
          </button>
        </div>
        <div class="col-sm-auto pe-0 mb-3 mb-sm-0">
          <!-- <button type="button" class="button btn-small btn-secondary-solid" style="width: 125px">
            Preview
          </button> -->
        </div>
        <div class="col-auto">
          <button
            type="button"
            class="button btn-small btn-success-solid"
            style="width: 125px"
            @click="Submit(false)"
          >
            Publish
          </button>
        </div>
      </div>
    </div>
    <div class="page-content">
      <h1
        class="heading-01 text-neutral-08 fw-semibold"
        style="margin-top: 40px; margin-bottom: 36px"
      >
        General information
      </h1>
      <div class="row">
        <div class="col col-lg-7 col-xl me-xxl-5 mb-3 mb-lg-0">
          <div class="pb-3 mb-1">
            <label for="title" class="label-02 text-neutral-07 fw-semibold mb-2">Title</label>
            <Field
              v-model="product.Title"
              type="text"
              name="title"
              id="title"
              class="form-control form-control-lg"
              placeholder="Name"
              :class="{ 'border-danger': errors.title }"
            />
            <ErrorMessage class="error-message" name="title" />
          </div>
          <div class="pb-3 mb-1">
            <label for="short-description" class="label-02 text-neutral-07 fw-semibold mb-2"
              >Short description</label
            >
            <Field
              v-model="product.ShortDescription"
              as="textarea"
              rows="3"
              cols="50"
              name="shortDescription"
              id="short-description"
              class="form-control form-control-lg"
              placeholder="Short Description.."
              :class="{ 'border-danger': errors.shortDescription }"
            ></Field>
            <ErrorMessage class="error-message" name="shortDescription" />
          </div>
          <label for="description" class="label-02 text-neutral-07 fw-semibold mb-2"
            >Description</label
          >

          <div class="form-control form-control-lg p-0 product-content">
            <Editor :showTitle="false" v-model="product.Description" />
          </div>
          <!-- <Field
            v-model="product.Description"
            as="textarea"
            rows="5"
            cols="50"
            name="description"
            id="description"
            class="form-control form-control-lg"
            placeholder="Description.."
            :class="{ 'border-danger': errors.description }"
          ></Field>
          <ErrorMessage class="error-message" name="description" /> -->
        </div>
        <div class="col-lg-5 col-xl">
          <label class="label-02 text-neutral-07 fw-semibold mb-2">Thumbnail</label>
          <div class="thumbnail card">
            <div class="card-body">
              <img :src="$utils.GetImageFormat(product.ImgBase64)" />
            </div>
            <div class="card-footer">
              <div class="row align-items-center">
                <div class="col paragraph-02 text-neutral-08">
                  Select thumbnail image <br />
                  for this product
                </div>
                <div class="col-auto d-flex justify-content-between">
                  <label
                    for="change-thumbnail"
                    style="width: 126px"
                    class="button btn-small btn-success-solid me-3 text-center"
                    >Upload image</label
                  >
                  <input
                    accept="image/*"
                    type="file"
                    id="change-thumbnail"
                    class="d-none"
                    @input="$utils.InputImage(product)"
                  />
                  <button
                    type="button"
                    class="button btn-small btn-neutral-outlined fw-semibold"
                    style="width: 126px"
                    @click="product.ImgBase64 = ''"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
  <!-- Navigation throw the product details -->
  <ul class="nav nav-tabs mt-5" id="editProduct" role="tablist" v-if="windowWidth > 1500">
    <li class="nav-item">
      <button
        class="nav-link active"
        id="details-tab"
        data-bs-toggle="tab"
        data-bs-target="#details"
        type="button"
        @click="selectedTab = 'details'"
      >
        Details
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="pricing-tab"
        data-bs-toggle="tab"
        data-bs-target="#pricing"
        type="button"
        @click="selectedTab = 'priceing'"
      >
        Pricing
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="inventory-tab"
        data-bs-toggle="tab"
        data-bs-target="#inventory"
        type="button"
        @click="selectedTab = 'inventory'"
      >
        Inventory
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="gallery-tab"
        data-bs-toggle="tab"
        data-bs-target="#gallery"
        type="button"
        @click="GetProductGallery(productIdParam)"
      >
        Gallery
      </button>
    </li>
    <li class="nav-item">
      <button
        @click="GetRecomandations(productIdParam)"
        class="nav-link"
        type="button"
        id="recomandation-tab"
        data-bs-toggle="tab"
        data-bs-target="#recomandation"
      >
        Recomandations
      </button>
    </li>
    <li class="nav-item">
      <button
        @click="GetPackages(productIdParam)"
        class="nav-link"
        id="package-tab"
        data-bs-toggle="tab"
        data-bs-target="#package"
        type="button"
      >
        Package
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="specifications-tab"
        data-bs-toggle="tab"
        data-bs-target="#specifications"
        type="button"
        @click="GetProductSpecificationSections(productIdParam)"
      >
        Specifications
      </button>
    </li>
    <li class="nav-item">
      <button
        class="nav-link"
        id="fidelity-tab"
        data-bs-toggle="tab"
        data-bs-target="#fidelity"
        type="button"
        @click="selectedTab = 'fidelity'"
      >
        Fidelity points
      </button>
    </li>
    <!-- <li class="nav-item">
      <button
        class="nav-link"
        id="associated-specifications-tab"
        data-bs-toggle="tab"
        data-bs-target="#associated-specifications"
        type="button"
        @click="
          selectedTab = 'associated-specifications';
          GetAssociatedSpecs(productIdParam);
        "
      >
        Associated specifications
      </button>
    </li> -->
    <li class="nav-item">
      <button
        class="nav-link"
        type="button"
        id="configuration-tab"
        data-bs-toggle="tab"
        data-bs-target="#configuration"
        @click="GetProductConfigurations(productIdParam)"
      >
        Configuration
      </button>
    </li>
  </ul>
  <div class="dropdown ms-5 ps-3" v-else>
    <button
      class="btn btn-outline-secondary btn-md dropdown-toggle text-start"
      type="button"
      id="productTabDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style="width: 200px"
    >
      {{ selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1) }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="productTabDropdown" id="addProduct" role="tablist">
      <li class="dropdown-item" :class="{ active: selectedTab == 'details' }">
        <button
          class="nav-link active"
          id="details-tab"
          data-bs-toggle="tab"
          data-bs-target="#details"
          type="button"
          @click="selectedTab = 'details'"
        >
          Details
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'pricing' }">
        <button
          class="nav-link"
          id="pricing-tab"
          data-bs-toggle="tab"
          data-bs-target="#pricing"
          type="button"
          @click="selectedTab = 'pricing'"
        >
          Pricing
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'inventory' }">
        <button
          class="nav-link"
          id="inventory-tab"
          data-bs-toggle="tab"
          data-bs-target="#inventory"
          type="button"
          @click="selectedTab = 'inventory'"
        >
          Inventory
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'gallery' }">
        <button
          class="nav-link"
          id="gallery-tab"
          data-bs-toggle="tab"
          data-bs-target="#gallery"
          type="button"
          @click="GetProductGallery(productIdParam)"
        >
          Gallery
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'recomandation' }">
        <button
          @click="GetRecomandations(productIdParam)"
          class="nav-link"
          type="button"
          id="recomandation-tab"
          data-bs-toggle="tab"
          data-bs-target="#recomandation"
        >
          Recomandations
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'package' }">
        <button
          @click="GetPackages(productIdParam)"
          class="nav-link"
          id="package-tab"
          data-bs-toggle="tab"
          data-bs-target="#package"
          type="button"
        >
          Package
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'specifications' }">
        <button
          class="nav-link"
          id="specifications-tab"
          data-bs-toggle="tab"
          data-bs-target="#specifications"
          type="button"
          @click="GetProductSpecificationSections(productIdParam)"
        >
          Specifications
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'fidelity' }">
        <button
          class="nav-link"
          id="fidelity-tab"
          data-bs-toggle="tab"
          data-bs-target="#fidelity"
          type="button"
          @click="selectedTab = 'fidelity'"
        >
          Fidelity points
        </button>
      </li>
      <li class="dropdown-item" :class="{ active: selectedTab == 'configuration' }">
        <button
          class="nav-link"
          type="button"
          id="configuration-tab"
          data-bs-toggle="tab"
          data-bs-target="#configuration"
          @click="GetProductConfigurations(productIdParam)"
        >
          Configuration
        </button>
      </li>
    </ul>
  </div>
  <div class="page-content mt-4 pt-2 mb-5 pb-5">
    <div class="tab-content" id="editProductTabContent">
      <div id="details" class="tab-pane fade show active">
        <ProductDetailsComponent
          ref="productDetailsRef"
          @onChangeCategory="OnChangeCategory"
          @getCategories="GetListOfCategories"
          @getBrands="GetListOfBrands"
          @getLocations="GetListOfLocations"
          @getStatus="GetListOfStatuses"
          :product="product"
          :categoriesList="categoriesList"
          :brandsList="brandsList"
          :subcategoriesList="subcategoriesList"
          :locationsList="locationsList"
          :statusesList="statusesList"
        />
      </div>
      <div id="pricing" class="tab-pane fade">
        <ProductPricingComponent
          ref="productPricingRef"
          :product="product"
          @remove-location="RemoveLocation"
        />
      </div>
      <div id="inventory" class="tab-pane fade">
        <ProductInventoryComponent
          ref="productInventoryRef"
          :product="product"
          @remove-location="RemoveLocation"
        />
      </div>
      <div id="gallery" class="tab-pane fade">
        <div class="row align-items-center">
          <div class="col">
            <div class="heading-01 fw-semibold text-neutral-08">Gallery</div>
            <small class="label-01 fw-normal text-neutral-04">
              {{ `${galleryList.length} / 8 images` }}
            </small>
          </div>
          <div class="col-auto d-flex align-items-center">
            <div class="label-01 fw-normal text-neutral-05">Max. 8 images</div>
            <div class="ms-4">
              <label
                :class="[galleryList.length >= 8 ? 'btn-disabled-solid' : 'btn-success-solid']"
                :title="[
                  galleryList.length >= 8 ? 'Maximum 8 images are allowed.' : 'Choose your images',
                ]"
                for="upload-gallery"
                style="width: 126px"
                class="button btn-small btn-success-solid text-center"
                >Upload image</label
              >
              <input
                :disabled="galleryList.length >= 8"
                accept="image/*"
                multiple
                type="file"
                id="upload-gallery"
                class="d-none"
                @input="AddImageToGallery"
              />
            </div>
          </div>
        </div>
        <div class="row mt-4 pt-2">
          <div
            class="col-md-6 col-lg-4 col-xl-3 col-xxl-2 mb-4"
            v-for="gallery in galleryList"
            :key="gallery.Id"
          >
            <ProductGalleryComponent :image="gallery" @delete="DeleteImageGallery(gallery)" />
          </div>
          <div class="col-md-6 col-lg-4 col-xl-3 col-xxl-2" v-if="galleryList.length < 8">
            <label for="upload-gallery" class="button btn-add-img-to-gallery">
              <div class="text-button-01 fw-semibold text-neutral-04 text-center">
                <img src="../../assets/images/icons/add-linear-32x32.svg" class="mb-2" />
                <div>Upload images</div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div id="recomandation" class="tab-pane fade">
        <ProductRecomandationComponent
          :selectedTab="selectedTab"
          :recomandations="recomandations"
          :productId="product.Id"
        />
      </div>
      <div id="package" class="tab-pane fade">
        <product-package-component
          :selectedTab="selectedTab"
          ref="productPackageRef"
          :packages="packages"
          :productId="product.Id"
        />
      </div>
      <div id="specifications" class="tab-pane fade">
        <ProductSpecificationsComponent
          :selectedTab="selectedTab"
          ref="productSpecificationsRef"
          :sections="specificationSections"
        />
      </div>
      <div id="fidelity" class="tab-pane fade">
        <ProductFidelityPoints ref="fidelityPointsRef" :fidelityPoints="fidelityPoints" />
      </div>
      <!-- <div id="associated-specifications" class="tab-pane fade">
        <ProductAssociatedSpecs
          :selectedTab="selectedTab"
          ref="associatedSpecRef"
          :associatedSpecifications="associatedSpecifications"
          @get="GetAssociatedSpecs(this.productIdParam)"
          @delete="DeleteAssociatedSpec"
          @add="AddAssociatedSpec"
        />
      </div> -->
      <div id="configuration" class="tab-pane fade">
        <ProductConfigurationComponent
          :configurations="product.Configurations"
          :idProduct="product.Id"
          @save-config="SaveConfiguration"
          @save-specification="SaveSpecification"
          @edit="EditConfiguration"
          @delete-config="DeleteConfiguration"
          @delete-specification="DeleteSpecificationFromConfiguration"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import ProductDetailsComponent from '../../components/Product/ProductDetailsComponent.vue';
import ProductPricingComponent from '../../components/Product/ProductPricingComponent.vue';
import ProductGalleryComponent from '../../components/Product/ProductGalleryComponent.vue';
import ProductSpecificationsComponent from '../../components/Product/ProductSpecifications/ProductSpecificationsComponent.vue';
import ProductInventoryComponent from '../../components/Product/ProductInventoryComponent.vue';
import ProductFidelityPoints from '../../components/Product/ProductFidelityPointsComponent.vue';
import ProductConfigurationComponent from '../../components/Product/ProductConfigurationComponent.vue';
import ProductRecomandationComponent from '../../components/Product/ProductRecomandations/ProductRecomandationComponent.vue';
import ProductPackageComponent from '../../components/Product/ProductPackage/ProductPackageComponent.vue';
// import ProductAssociatedSpecs from '../../components/Product/ProductAssociatedSpecs/ProductAssociatedSpecsComponent.vue';
import Editor from '../../components/Blog/Editor.vue';

export default {
  name: 'EditProductView',
  props: {
    productIdParam: {
      type: String,
      required: true,
    },
  },
  components: {
    ProductDetailsComponent,
    ProductGalleryComponent,
    ProductSpecificationsComponent,
    ProductPricingComponent,
    ProductInventoryComponent,
    ProductFidelityPoints,
    ProductConfigurationComponent,
    ProductRecomandationComponent,
    Form,
    Field,
    Editor,
    ErrorMessage,
    ProductPackageComponent,
    // ProductAssociatedSpecs,
  },
  data() {
    return {
      selectedTab: 'details',
      product: {},
      locationsList: [],
      recomandations: [],
      packages: [],
      categoriesList: [],
      brandsList: [],
      subcategoriesList: [],
      galleryList: [],
      statusesList: [],
      listImgDeleted: [],
      specificationCategories: [],
      specificationSections: [],
      fidelityPoints: {},
      associatedSpecifications: [],
      componentKey: 0,
      windowWidth: 0,
    };
  },
  methods: {
    SaveSpecification(param) {
      this.$axios
        .post('/api/ProductConfiguration/create', param.createdSpecification)
        .then((response) => {
          const newSpecification = {
            Id: response.data,
            SubconfigurationsCount: 0,
            ...param.createdSpecification,
          };
          if (!param.selectedConfig.Subconfigurations) {
            // eslint-disable-next-line no-param-reassign
            param.selectedConfig.Subconfigurations = [];
          }
          param.selectedConfig.Subconfigurations.push(newSpecification);
          // eslint-disable-next-line no-param-reassign
          param.selectedConfig.SubconfigurationsCount += 1;
          this.$utils.toastNotify('success', 'Configuration added succesfully');
          // eslint-disable-next-line no-undef
          $('#addConfigurationModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    SaveConfiguration(config) {
      this.$axios
        .post('/api/ProductConfiguration/create', config)
        .then((response) => {
          const newConfig = {
            Id: response.data,
            SubconfigurationsCount: 0,
            ...config,
          };
          this.product.Configurations.push(newConfig);
          this.$utils.toastNotify('success', 'Configuration added succesfully');
          // eslint-disable-next-line no-undef
          $('#addConfigurationModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    EditConfiguration(config) {
      this.$axios
        .put(`/api/ProductConfiguration/update/${config.Id}`, config)
        .then(() => {
          // this.GetProductConfigurations(this.productIdParam);
          this.$utils.toastNotify('success', 'Configuration edited succesfully');
          // eslint-disable-next-line no-undef
          $('#editConfigurationModal').modal('hide');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteConfiguration(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/ProductConfiguration/delete/${id}`)
              .then(() => {
                // this.GetProductConfigurations(this.productIdParam);
                if (this.product.Configurations.length > 1) {
                  const index = this.product.Configurations.findIndex((item) => item.Id === id);
                  this.product.Configurations.splice(index, 1);
                } else this.product.Configurations = [];
                this.$utils.toastNotify('success', 'Category deleted successfully.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    DeleteSpecificationFromConfiguration(param) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/ProductConfiguration/delete/${param.id}`)
              .then(() => {
                if (
                  param.configuration.Subconfigurations &&
                  param.configuration.Subconfigurations.length > 1
                ) {
                  const index = param.configuration.Subconfigurations.findIndex(
                    (item) => item.Id === param.id,
                  );
                  param.configuration.Subconfigurations.splice(index, 1);
                  // eslint-disable-next-line no-param-reassign
                  param.configuration.SubconfigurationsCount -= 1;
                } else {
                  // eslint-disable-next-line no-param-reassign
                  param.configuration.Subconfigurations = [];
                  // eslint-disable-next-line no-param-reassign
                  param.configuration.IsExpanded = false;
                  // eslint-disable-next-line no-param-reassign
                  param.configuration.SubconfigurationsCount = 0;
                }
                this.$utils.toastNotify('success', 'Category deleted successfully.');
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async Submit(draft) {
      const validateThumbail = await this.$refs.productThumbnailRef
        .validate()
        .then((result) => result.valid);
      const validateDetails = await this.$refs.productDetailsRef.Submit();
      const validatePricing = await this.$refs.productPricingRef.Submit();
      const validateInventory = await this.$refs.productInventoryRef.Submit();
      const validatePackage = await this.$refs.productPackageRef.Submit();
      const validateFidelityPoints = await this.$refs.fidelityPointsRef.Submit();
      const validateSpecifications = await this.$refs.productSpecificationsRef.Submit();
      const valid = [];

      if (validateDetails === false) {
        valid.push('Details');
        const triggerEl = document.querySelector('#myTab a[href="#profile"]');
        this.$bootstrap.Tab.getInstance(triggerEl).show(); // Select tab by name
      }
      if (validatePricing === false) {
        valid.push('Pricing');
      }
      if (validatePackage === false) {
        valid.push('Package');
      }
      if (validateFidelityPoints === false) {
        valid.push('Fidelity');
      }
      if (validateInventory === false) {
        valid.push('Inventory');
      }
      if (validateSpecifications === false) {
        valid.push('Specifications');
      }
      if (validateThumbail === false) {
        valid.push('General information');
      }
      if (valid.length === 0) {
        this.EditProduct(draft);
      } else {
        // eslint-disable-next-line no-undef
        $(`#${valid[0].toLocaleLowerCase()}-tab`).tab('show');
        this.$utils.toastNotify('warning', `Please check again ${valid}`);
      }
    },
    SaveSpecificationSections() {
      const sectionsReturn = [];
      this.specificationSections.forEach((section) => {
        const specs = [];
        section.Specifications.forEach((specification) => {
          let check = true;
          if (specification.ValueDetails) {
            if (specification.ValueDetails.Id !== null) {
              specs.push({
                Name: specification.Name,
                SpecificationId: specification.SpecificationId,
                ValueId: specification.ValueDetails.Id,
              });
              check = false;
            }
          }
          if (check) {
            specs.push({
              Name: specification.Name,
              SpecificationId: specification.SpecificationId,
              Value: specification.Value,
            });
          }
          return specs;
        });
        sectionsReturn.push({
          Id: section.Id,
          Name: section.Name,
          Specifications: specs,
        });
      });
      return sectionsReturn;
    },
    // Save new images that need to be added in a list with proprety Added: 1
    // that mean is not saved in DB yet
    AddImageToGallery(event) {
      const { files } = event.target;
      if (this.galleryList.length + files.length > 8) {
        this.$utils.toastNotify('warning', 'Maximum 8 images.');
        return;
      }
      for (let i = 0; i < files.length; i += 1) {
        if (!files[i].type.includes('image')) {
          this.$utils.toastNotify('warning', 'Only images are allowed.');
          return;
        }
        // 2 544 856 == 2.42mb
        if (files[i].size > 1055000) {
          this.$utils.toastNotify('warning', 'Image size should be maximum 1MB.');
          return;
        }
        const reader = new FileReader();
        reader.onload = () => {
          // eslint-disable-next-line vue/no-mutating-props
          const ImgBase64 = reader.result;
          this.galleryList.push({
            Name: 'Image',
            ImgBase64,
            Added: 1,
          });
        };
        if (files[i]) {
          reader.readAsDataURL(files[i]);
        } // Citiți fișierul ca un URL de date
      }
    },

    OnChangeCategory(id) {
      this.GetListOfSubcategories(id);
      this.product.SubcategoryId = null;
      this.product.Subcategory = null;
    },
    EditProduct(draft) {
      const Product = {
        Title: this.product.Title,
        ShortDescription: this.product.ShortDescription,
        Description: this.product.Description,
        Tag: this.product.Tag,
        RegularPrice: this.product.RegularPrice || 0,
        SalePrice: this.product.SalePrice || 0,
        PurchasePrice: this.product.PurchasePrice || 0,
        Tva: this.product.Tva,
        Supplier: this.product.Supplier,
        Sku: this.product.Sku,
        Stock: this.product.Stock,
        Draft: draft,
        UpdatedBy: this.currentUser.UserId,
        CategoryId: this.product.Category === null ? null : this.product.Category.Id,
        SubcategoryId: this.product.Subcategory === null ? null : this.product.Subcategory.Id,
        BrandId: this.product.Brand === null ? null : this.product.Brand.Id,
        ImgBase64: this.product.ImgBase64,
        StatusId: this.product.Status ? this.product.Status.Id : null, // tabela status
        OnSale: this.product.OnSale,
        InStock: this.product.InStock,
        IsAvailable: this.product.IsAvailable,
        ...(this.product.PriorityNumber ? { PriorityNumber: this.product.PriorityNumber } : ''),
      };

      const addPackage = [];
      this.packages.forEach((element) => {
        addPackage.push({
          RecommendedProductId: element.AssociatedProductId,
          DiscountProduct: element.DiscountProduct,
          DiscountAssociatedProduct: element.DiscountAssociatedProduct,
        });
      });

      const editProduct = {
        Product,
        ProductGallery: this.SaveGalleryList(),
        ListImgDeleted: this.listImgDeleted,
        ProductLocations: this.product.Locations,
        Sections: this.SaveSpecificationSections(),
        FidelityPoints: this.fidelityPoints,
        Recomandations:
          this.recomandations.length > 0
            ? this.recomandations.map((product) => product.ProductId)
            : null,
        Packages: addPackage.length > 0 ? addPackage : null,
      };
      this.$axios
        .put(`/api/Product/update/${this.productIdParam}`, editProduct)
        .then(() => {
          this.galleryList = [];
          this.listImgDeleted = [];
          this.RefreshProductGallery(this.productIdParam);
          this.$utils.toastNotify('success', 'Product edited successfully.');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    // Save images that are not saved yet in DB
    SaveGalleryList() {
      const saveGallery = [];
      this.galleryList.forEach((prod) => {
        if (prod.Added === 1) {
          saveGallery.push(prod);
          // eslint-disable-next-line no-param-reassign
          prod.Added = 0;
        }
      });
      return saveGallery;
    },

    async GetListOfCategories() {
      await this.$axios
        .get('/api/categories/getListOfCategories')
        .then((response) => {
          this.categoriesList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfBrands() {
      this.$axios
        .get('/api/Brand/getListOfBrands')
        .then((response) => {
          this.brandsList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfSubcategories(id) {
      this.$axios
        .get(`/api/categories/subcategories/${id}`)
        .then((response) => {
          this.subcategoriesList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfStatuses() {
      this.$axios
        .get('/api/ProductStatus/getList')
        .then((response) => {
          this.statusesList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetProductGallery(Id) {
      this.$axios
        .get(`/api/Product/getProductWithProductsGallery/${Id}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          this.galleryList = response.data;
          this.selectedTab = 'gallery';
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    RefreshProductGallery(Id) {
      this.$axios
        .get(`/api/Product/getProductWithProductsGallery/${Id}`)
        .then((response) => {
          this.galleryList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetProductLocations(productId) {
      this.$axios
        .get(`/api/ProductLocation/get/${productId}`)
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          this.product.Locations = response.data;
          this.forceRerender();
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetProductConfigurations(productId) {
      this.$axios
        .get(`/api/ProductConfiguration/getProductConfigurations/${productId}`)
        .then((response) => {
          this.product.Configurations = response.data;
          this.selectedTab = 'configuration';
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    async GetProduct(id) {
      await this.$axios
        .get(`/api/Product/get/${id}`)
        .then((response) => {
          this.product = response.data;
          this.fidelityPoints = response.data.FidelityPoints;
          if (this.product.Category) {
            this.GetListOfSubcategories(this.product.Category.Id);
          }
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetListOfLocations() {
      this.$axios
        .get('/api/Location/getListOfLocations')
        .then((response) => {
          this.locationsList = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetProductSpecificationSections(id) {
      this.$axios
        .get(`/api/ProductSpecificationsSection/getProductSections/${id}`)
        .then((response) => {
          this.specificationSections = response.data;
          this.selectedTab = 'specifications';
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetSpecifcationCategories() {
      this.$axios
        .get('/api/SpecificationCategory/getAll')
        .then((response) => {
          this.specificationCategories = response.data.Items;
          // eslint-disable-next-line no-undef
          $('#addSectionModal').modal('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetRecomandations(id) {
      this.$axios
        .get(`/api/ProductRecomandation/getRecomandations/${id}`)
        .then((response) => {
          this.recomandations = response.data.Items;
          // add pagination if needed
          this.selectedTab = 'recomandation';
          // eslint-disable-next-line no-undef
          $('#recomandation-tab').tab('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    GetPackages(id) {
      this.$axios
        .get(`/api/ProductPackage/get/${id}`)
        .then((response) => {
          this.packages = response.data.Items;
          // add pagination if needed
          this.selectedTab = 'package';
          // eslint-disable-next-line no-undef
          $('#package-tab').tab('show');
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong.');
        });
    },
    DeleteImageGallery(prodGallery) {
      if (!prodGallery.Added) {
        // imaginea e deja in baza de date
        this.$swal
          .fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          })
          .then((result) => {
            if (result.isConfirmed) {
              // eslint-disable-next-line no-param-reassign
              this.listImgDeleted.push(prodGallery.Id);
              const index = this.galleryList.findIndex((element) => element.Id === prodGallery.Id);
              if (index !== -1) {
                this.galleryList.splice(index, 1); // Eliminăm elementul din array
              }
              this.$utils.toastNotify('success', 'Your file has been deleted.');
            }
          });
      }
      if (prodGallery.Added === 1) {
        this.$swal
          .fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
          })
          .then((result) => {
            if (result.isConfirmed) {
              // eslint-disable-next-line no-param-reassign
              const index = this.galleryList.findIndex(
                (element) => element.ImgBase64 === prodGallery.ImgBase64,
              );
              if (index !== -1) {
                this.galleryList.splice(index, 1); // Eliminăm elementul din array
              }
              this.$utils.toastNotify('success', 'Your file has been deleted.');
            }
          });
      }
    },
    DeleteProduct(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`/api/Product/delete/${id}`)
              .then(() => {
                this.$utils.toastNotify('success', 'Your file has been deleted.');

                this.$router.push({ path: '/Brands' });
              })
              .catch(() => {
                this.$utils.toastNotify('error', 'Something went wrong.');
              });
          }
        });
    },
    RemoveLocation(location) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            const index = this.product.Locations.indexOf(location);
            this.product.Locations[index] = {};
            this.product.Locations.splice(index, 1);
            this.$utils.toastNotify('success', 'Your file has been deleted.');
          }
        });
    },
    PopulateLists() {
      this.GetListOfCategories();
      this.GetListOfBrands();
      this.GetListOfLocations();
      this.GetListOfStatuses();
    },
    GoBackButton() {
      this.$router.push({ name: 'ProductView' });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    schema() {
      return yup.object().shape(
        {
          title: yup
            .string()
            .required('Title is required.')
            .min(3, 'Product title must have at least 3 characters')
            .max(50, 'Product title must be under 50 characters')
            .trim(),
          description: yup
            .string()
            .nullable()
            .notRequired()
            .when('description', {
              is: (value) => value?.length,
              then: (rule) =>
                // eslint-disable-next-line implicit-arrow-linebreak
                rule.max(2000, 'Description must have maximum 2000 characters'),
            }),
          shortDescription: yup
            .string()
            .notRequired()
            .matches(/^(|.{5,500})$/, 'Between 5-500 characters')
            .trim(),
        },
        [
          // Add Cyclic deps here because when require itself
          ['description', 'description'],
        ],
      );
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.windowWidth = window.innerWidth;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.GetProduct(this.productIdParam);
    // this.PopulateLists();
    // this.GetProductGallery(this.productIdParam);
    // this.GetProductSpecificationSections(this.productIdParam);
    this.GetProductLocations(this.productIdParam);
    // this.GetProductConfigurations(this.productIdParam);
    this.$store.commit('ChangePage', 'Products');
  },
};
</script>
<style scoped>
.page-header-height {
  height: 100%;
}
@media only screen and (min-width: 992px) {
  .page-header-height {
    height: 102px;
  }
}

.btn-add-img-to-gallery {
  object-fit: cover;
  width: 100%;
  height: 182px !important;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.btn-add-img-to-gallery:hover {
  background-color: var(--neutral-2);
}

.thumbnail.card {
  border-radius: 16px;
  border-color: var(--blacktint-2);
}
.thumbnail.card .card-body img {
  max-width: 415px;
  width: 100%;
  height: 264px;
  object-fit: contain;
  border-radius: 8px;
  border: 1px solid var(--blacktint-2);
  background-color: white;
}
.thumbnail.card .card-body {
  background-color: #f5f6f9;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 309px;
}
.thumbnail.card .card-footer {
  padding-top: 13px;
  padding-bottom: 13px;
  border-color: var(--blacktint-2);
  background-color: transparent;
}
</style>
