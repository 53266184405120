<!-- eslint-disable vue/valid-v-model -->
<template>
  <div
    class="modal fade"
    id="addSpecificationcategoryModal"
    tabindex="-1"
    aria-labelledby="addSpecificationCategoryModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <Form
          ref="addCategoryFormRef"
          @submit="Submit"
          :validation-schema="Schema"
          v-slot="{ errors }"
        >
          <div class="modal-header">
            <img src="../../../../assets/images/icons/add-linear-20x20.svg" class="action-icon" />
            <h5 class="modal-title" id="addSpecificationCategoryModalLabel">Add category</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="ClearModal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="category">Category name</label>
              <Field
                type="text"
                name="category"
                class="form-control"
                v-model="category.Name"
                placeholder="Category name"
                :class="{ 'border-danger': errors.category }"
              >
              </Field>
              <ErrorMessage name="category" class="error-message" />
            </div>

            <label>Specifications</label>
            <small class="d-block label-01 mb-2 text-neutral-04">
              The values of the specifications can be added after their creation.
            </small>
            <div class="row mb-2" v-for="(spec, index) in specificationsList" :key="index">
              <div class="col pe-0">
                <Field
                  :name="`specifications[${index}].spec`"
                  v-model="spec.Name"
                  type="text"
                  class="form-control rounded-1"
                  :placeholder="`Specification ${index + 1}`"
                  :class="{ 'border-danger': errors[`specifications[${index}].spec`] }"
                >
                </Field>
              </div>
              <div class="col-auto">
                <button type="button" class="button table-btn h-100" @click="DeleteValue(index)">
                  <img src="../../../../assets/images/icons/trash-linear-red.svg" />
                </button>
              </div>
              <div class="col-12">
                <ErrorMessage :name="`specifications[${index}].spec`" class="error-message" />
              </div>
            </div>

            <button
              type="button"
              class="button add-spec-button paragraph-02 text-neutral-04 w-100"
              @click="AddSpecification"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10H15"
                  stroke="#95A6AC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 15V5"
                  stroke="#95A6AC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Add specification
            </button>
          </div>
          <div class="modal-footer justify-content-center">
            <button class="button btn-success-solid btn-medium w-100">Save</button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { Form, Field, ErrorMessage } from 'vee-validate';

import * as yup from 'yup';

export default {
  name: 'AddSpecificationCategoryModal',
  data() {
    return {
      category: {},
      specificationsList: [],
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    DeleteValue(index) {
      this.specificationsList.splice(index, 1);
    },
    Submit() {
      this.category.Specifications = this.specificationsList.filter((x) => x.Name !== '');
      this.$emit('save', this.category);
    },
    AddSpecification() {
      this.specificationsList.push({
        Name: this.category.Spec,
        SpecificationCategoryId: 0,
      });
    },
    ClearModal() {
      this.$refs.addCategoryFormRef.resetForm();
      this.specificationsList = [
        {
          Name: '',
          SpecificationCategoryId: 0,
        },
      ];
    },
  },
  computed: {
    Schema() {
      return yup.object().shape({
        category: yup
          .string()
          .required('Name field is required.')
          .min(3, 'Category title must have at least 3 characters')
          .max(200, 'Category title be under 200 characters')
          .trim(),
        specifications: yup.array().of(
          yup.object().shape({
            spec: yup
              .string()
              .notRequired()
              .test('spec-min', 'Specification name must have at least 3 characters', (spec) => {
                if (!spec || (spec && spec.length >= 3)) return true;
                return false;
              })
              .test('spec-max', 'Specification name be under 200 characters', (spec) => {
                if (!spec || (spec && spec.length < 200)) return true;
                return false;
              })
              .trim(),
          }),
        ),
      });
    },
  },
};
</script>
<style scoped>
.add-spec-button {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px dashed var(--neutral-3);
  transition: 0.2s;
}

.add-spec-button:hover {
  background-color: var(--neutral-2);
}
</style>
