<template>
  <div class="page-header">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <svg
            width="32"
            class="page-header-img"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 20.5C18.2091 20.5 20 18.7091 20 16.5C20 14.2909 18.2091 12.5 16 12.5C13.7909 12.5 12 14.2909 12 16.5C12 18.7091 13.7909 20.5 16 20.5Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2.66602 17.6736V15.3269C2.66602 13.9402 3.79935 12.7936 5.19935 12.7936C7.61268 12.7936 8.59935 11.0869 7.38602 8.99357C6.69268 7.79357 7.10602 6.23357 8.31935 5.54024L10.626 4.22024C11.6793 3.59357 13.0393 3.96691 13.666 5.02024L13.8127 5.27357C15.0127 7.36691 16.986 7.36691 18.1993 5.27357L18.346 5.02024C18.9727 3.96691 20.3327 3.59357 21.386 4.22024L23.6927 5.54024C24.906 6.23357 25.3193 7.79357 24.626 8.99357C23.4127 11.0869 24.3994 12.7936 26.8127 12.7936C28.1994 12.7936 29.346 13.9269 29.346 15.3269V17.6736C29.346 19.0602 28.2127 20.2069 26.8127 20.2069C24.3994 20.2069 23.4127 21.9136 24.626 24.0069C25.3193 25.2202 24.906 26.7669 23.6927 27.4602L21.386 28.7802C20.3327 29.4069 18.9727 29.0336 18.346 27.9802L18.1993 27.7269C16.9993 25.6336 15.026 25.6336 13.8127 27.7269L13.666 27.9802C13.0393 29.0336 11.6793 29.4069 10.626 28.7802L8.31935 27.4602C7.10602 26.7669 6.69268 25.2069 7.38602 24.0069C8.59935 21.9136 7.61268 20.2069 5.19935 20.2069C3.79935 20.2069 2.66602 19.0602 2.66602 17.6736Z"
              stroke="#1F2629"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="heading-04 fw-semibold d-none d-sm-block">{{ $route.name }}</div>
          <div class="heading-02 fw-semibold d-sm-none">{{ $route.name }}</div>
        </div>
      </div>
      <div class="col-auto">
        <button
          @click="EditSettings()"
          type="button"
          class="button btn-small btn-success-solid d-flex align-items-center pe-3"
        >
          Save <span class="d-none d-sm-inline-block">changes</span>
        </button>
      </div>
    </div>
  </div>
  <Form :validation-schema="schema" v-slot="{ errors }" ref="settingsFormRef">
    <div class="page-content row ms-5 me-5 mt-5 mb-3" style="margin-top: 12px">
      <div class="col-xl d-flex flex-column justify-content-between">
        <div class="settings-border">
          <div class="heading-02 fw-semibold mb-3 mt-2">Online payment details:</div>
          <div class="mb-3">
            <label class="label-02 fw-semibold mb-2" for="companyCode">Company VAT code:</label>
            <Field
              v-model="settings.CompanyVatCode"
              type="text"
              class="form-control form-control-lg"
              placeholder="VAT code"
              id="stripeToken"
              name="companyCode"
              :class="{ 'border-danger': errors.companyCode }"
            />
            <ErrorMessage name="companyCode" class="error-message" />
          </div>
          <div class="mb-3">
            <label class="label-02 fw-semibold mb-2" for="smartBill">Smart bill:</label>
            <Field
              v-model="settings.SmartbillToken"
              type="text"
              class="form-control form-control-lg"
              placeholder="Smart bill code"
              id="smartBill"
              name="smartBill"
              :class="{ 'border-danger': errors.smartBill }"
            />
            <ErrorMessage name="smartBill" class="error-message" />
          </div>
          <div class="mb-3">
            <label class="label-02 fw-semibold mb-2" for="stripeToken">Stripe:</label>
            <Field
              v-model="settings.StripeToken"
              type="text"
              class="form-control form-control-lg"
              placeholder="Stripe code"
              id="stripeToken"
              name="stripeToken"
              :class="{ 'border-danger': errors.stripeToken }"
            />
            <ErrorMessage name="stripeToken" class="error-message" />
          </div>
        </div>
        <div class="settings-border d-flex flex-column justify-content-end">
          <div class="heading-02 fw-semibold mb-3">Order details:</div>
          <div class="mb-3">
            <div class="row align-items-center">
              <div class="col-xxl">
                <label class="label-02 fw-semibold mb-2" for="fidelityPointsValue"
                  >Number of days to prepare an order</label
                >
                <div class="input-group right">
                  <Field
                    v-model="settings.MinPickUpFieldDate"
                    :disabled="!settings.PickUpFieldDate"
                    type="number"
                    class="form-control form-control-lg"
                    placeholder="Preparation days"
                    id="minPickUpFieldDate"
                    name="minPickUpFieldDate"
                    :class="{ 'border-danger': errors.minPickUpFieldDate }"
                  />
                  <span
                    v-if="settings.MinPickUpFieldDate > 1"
                    class="input-group-text"
                    id="minPickUpFieldDate-text"
                    >Days</span
                  >
                  <span v-else class="input-group-text" id="minPickUpFieldDate-text">Day</span>
                </div>
                <ErrorMessage name="minPickUpFieldDate" class="error-message" />
              </div>
              <div class="col-xxl">
                <label for=""></label>
                <div class="row align-items-center">
                  <div class="col-auto">
                    <label
                      class="label-02 fw-semibold mb-2"
                      for="switch-shadow-displayed-on-client-edit"
                      >Customers can select a day for pick-up:</label
                    >
                  </div>
                  <div class="col-auto">
                    <span>
                      <input
                        id="switch-shadow-displayed-on-client-edit"
                        class="switch-small switch-small--shadow"
                        type="checkbox"
                        v-model="settings.PickUpFieldDate"
                        @change="ChangePickUpFieldDate"
                      />
                      <label for="switch-shadow-displayed-on-client-edit"></label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="label-02 fw-semibold mb-2" for="fidelityPointsValue"
              >Fidelity point value:</label
            >
            <div class="input-group right">
              <Field
                v-model="settings.FidelityPointsValue"
                type="number"
                class="form-control form-control-lg"
                placeholder="Enter fidelity points number"
                id="fidelityPointsValue"
                name="fidelityPointsValue"
                :class="{ 'border-danger': errors.fidelityPointsValue }"
              />
              <span class="input-group-text" id="fidelityPointsValue-text">RON</span>

              <ErrorMessage name="fidelityPointsValue" class="error-message" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl d-flex flex-column justify-content-between">
        <div class="col">
          <div class="settings-border">
            <div class="heading-02 fw-semibold mb-3 mt-2">Email server details:</div>
            <div class="mb-3">
              <label class="label-02 fw-semibold mb-2" for="emailFrom">Email from</label>
              <Field
                v-model="settings.EmailFrom"
                type="text"
                class="form-control form-control-lg"
                placeholder="Email from"
                id="emailFrom"
                name="emailFrom"
                :class="{ 'border-danger': errors.emailFrom }"
              />
              <ErrorMessage name="emailFrom" class="error-message" />
            </div>
            <div class="mb-3">
              <label class="label-02 fw-semibold mb-2" for="smtpUser">Smtp user</label>
              <Field
                v-model="settings.SmtpUser"
                type="text"
                class="form-control form-control-lg"
                placeholder="Smtp user"
                id="smtpUser"
                name="smtpUser"
                :class="{ 'border-danger': errors.smtpUser }"
              />
              <ErrorMessage name="smtpUser" class="error-message" />
            </div>
            <div class="mb-3">
              <label class="label-02 fw-semibold mb-2" for="smtpPass">Smtp pass</label>
              <Field
                v-model="settings.SmtpPass"
                type="text"
                class="form-control form-control-lg"
                placeholder="Smtp pass"
                id="smtpPass"
                name="smtpPass"
                :class="{ 'border-danger': errors.smtpPass }"
              />
              <ErrorMessage name="smtpPass" class="error-message" />
            </div>
            <div class="mb-3">
              <label class="label-02 fw-semibold mb-2" for="smtpHost">Smtp host</label>
              <Field
                v-model="settings.SmtpHost"
                type="text"
                class="form-control form-control-lg"
                placeholder="Email host"
                id="smtpHost"
                name="smtpHost"
                :class="{ 'border-danger': errors.smtpHost }"
              />
              <ErrorMessage name="smtpHost" class="error-message" />
            </div>
            <div class="mb-3">
              <label class="label-02 fw-semibold mb-2" for="smtpPort">Smtp port</label>
              <Field
                v-model="settings.SmtpPort"
                type="number"
                class="form-control form-control-lg"
                placeholder="Smtp port"
                id="smtpPort"
                name="smtpPort"
                :class="{ 'border-danger': errors.smtpPort }"
              />
              <ErrorMessage name="smtpPort" class="error-message" />
            </div>
            <div class="mb-3">
              <label class="label-02 fw-semibold mb-2" for="secret">Email secret</label>
              <Field
                v-model="settings.Secret"
                type="text"
                class="form-control form-control-lg"
                placeholder="Email secret"
                id="secret"
                name="secret"
                :class="{ 'border-danger': errors.secret }"
              />
              <ErrorMessage name="secret" class="error-message" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content row ms-5 me-5 " style="margin-top: 24px;" >
      <div class="col-xl">
        <div class="settings-border">
          <div class="heading-02 fw-semibold mb-3 mt-2">Contact details:</div>
          <div class="mb-3">
            <label class="label-02 fw-semibold mb-2" for="email">Contact email</label>
            <Field
              v-model="settings.Email"
              type="text"
              class="form-control form-control-lg"
              placeholder="Conctact email"
              id="email"
              name="email"
              :class="{ 'border-danger': errors.email }"
            />
            <ErrorMessage name="email" class="error-message" />
          </div>
        </div>
      </div>
      <div class="col-xl"></div>
    </div>
  </Form>
</template>

<script>
import * as yup from 'yup';
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
  name: 'SettingsView',
  data() {
    return {
      settings: {
        FidelityPointsValue: 0,
        SmartbillToken: '',
        StripeToken: '',
        CompanyVatCode: '',
        PickUpFieldDate: false,
        MinPickUpFieldDate: 0,
        Email: '',
      },
      enableYupSchema: false,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  methods: {
    GetSettings() {
      this.$axios
        .get('/api/Settings/getAll')
        .then((response) => {
          this.settings = response.data;
        })
        .catch(() => {
          this.$utils.toastNotify('error', 'Something went wrong');
        });
    },
    ChangePickUpFieldDate() {
      if (!this.settings.PickUpFieldDate) {
        this.settings.MinPickUpFieldDate = 0;
      }
    },
    async EditSettings() {
      const form = await this.$refs.settingsFormRef.validate();
      if (form.valid) {
        this.$axios
          .put('/api/Settings/edit', this.settings)
          .then(() => {
            this.$utils.toastNotify('success', 'Settings have been modified succesfuly');
          })
          .catch(() => {
            this.$utils.toastNotify('error', 'Something went wrong');
          });
      }
    },
  },
  computed: {
    schema() {
      return yup.object({
        fidelityPointsValue: yup
          .number('Fidelity points value is required')
          .typeError('Fidelity points value is required')
          .positive('Value cannot be negative')
          .required('Fidelity points value is required')
          .test('is-decimal', 'The value should be a integer number', (number) => {
            if (!number) {
              return true;
            }
            return /^\d+(\.\d{1,1})?$/.test(number);
          }),
        minPickUpFieldDate: yup
          .number('Number of days is required')
          .typeError('Number of days is required')
          .required('Number of days is required')
          .test('is-decimal', 'The value should be a integer number', (number) => {
            if (!number) {
              return true;
            }
            return /^\d+(\.\d{1,1})?$/.test(number);
          }),
        smartBill: yup.string().required('Smart bill token is requierd'),
        stripeToken: yup.string().required('Stripe token is requierd'),
        companyCode: yup.string().required('Company vat code is requierd'),
        email: yup
          .string()
          .required('Contact email is required')
          .test('valid-email', 'Please enter a valid email', (email) => {
            if (email.match('@')) {
              return true;
            }
            return false;
          }),
      });
    },
  },
  created() {
    this.GetSettings();
  },
};
</script>

<style scoped>
.form-box {
  margin: auto;
  width: 100%;
}

@media (min-width: 1200px) {
  .form-box {
    max-width: 40vw;
  }
}

.settings-border {
  border: 1px solid #c3cccf;
  border-radius: 15px;
  padding: 16px;
  margin: 0px 12px;
}
.input-group-text {
  border-color: var(--bs-border-color) !important;
}
</style>
